import $ from 'jquery';
import Noty from 'noty';

export default function sendEmail() {
  $('body').on('submit', '.contact-form', async (e) => {
    e.preventDefault()

    const self = $(e.target);
    const formData = self.serializeArray();
    const sendData = new FormData();
    const newForm = {};
    const notyNormalize = {
      layout: 'topRight',
      timeout: 3000
    }

    formData.forEach( value => {
        newForm[value.name] = value.value;
    });

    sendData.append( 'action', 'divi_send_email' );
    sendData.append( 'subject', self.attr('subject') );
    sendData.append( 'to', self.attr('to') );
    sendData.append( 'body', JSON.stringify( newForm ) );

    // fetch options
    const url = '/wp-admin/admin-ajax.php';
    const options = {
        method: 'POST',
        body: sendData
    };

    try {
      $('body').addClass('loading');

      const res = await fetch( url, options )
      const data = await res.json()

      $('body').removeClass('loading');
      if ( data.status !== 200 || !data.status )
        return new Noty({
            ...notyNormalize,
            text: data.message,
            type: 'error'
        }).show();

      window.closePopup();
      $('.contact-form input[type="text"], .contact-form input[type="email"]').val('');
      return new Noty({
          ...notyNormalize,
          text: data.message,
          theme: 'relax',
          type: 'success'
      }).show();
    } catch(e) {
      console.warn(e);
      $('body').removeClass('loading');
      return new Noty({
          ...notyNormalize,
          text: 'Something went wrong, try again...',
          type: 'error'
      }).show();
    }
  })
}