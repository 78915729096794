import $ from 'jquery';

const windowResize = () => {
  $('[data-none]').map((index, item) => {
    if (window.innerWidth <= $(item).data('none')) {
      $(item).addClass('d-none');
    }
    else {
      $(item).removeClass('d-none');
    }
  });
};

const windowResizeInit = () => {
  windowResize();
  $(window).on('resize', windowResize);
};

export default windowResizeInit;

