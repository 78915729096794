import $ from 'jquery';
import Splide from '@splidejs/splide';

const collection = () => {
  if ($('.collection-slider').length === 0)
    return false;

  const collectionSlider = new Splide(".collection-slider", {
    drag: 'free',
    perPage: 3,
    perMove: 1,
    gap: 40,
    speed: 400,
    flickPower: 100,
    pagination: false,
    arrows: false,
    lazyLoad: 'sequential',
    breakpoints: {
      1360: {
        flickPower: 200,
        perPage: 2
      },
      960: {
        gap: 20,
        flickPower: 300,
        perPage: 1
      },
    }
  });

  const getListLength = () => {
    const self = collectionSlider.root;
    const list = self.querySelector('.splide__list');
    let items = list.querySelectorAll('.splide__slide');

    items = $(items).filter((index, item) => !$(item).hasClass('d-none') ? item : false);

    const listLength = items.length * ( $(items[1]).width() + collectionSlider.options.gap ) - $(list).width();

    return parseInt( (listLength - collectionSlider.options.gap).toFixed(2) );
  }

  const getCurrentPosition = () => {
    const self = collectionSlider.root;
    const list = self.querySelector('.splide__list');

    let currentPosition = list.style.transform;
    currentPosition = currentPosition.replace('translateX(', '');
    currentPosition = currentPosition.replace('px)', '');
    currentPosition = parseInt(currentPosition);

    return currentPosition;
  }
  
  const getCurrentPrecent = (isParallax = false) => {
    const listPercent = getListLength() / 100;
    const currentPosition = getCurrentPosition();

    let currentPercent = currentPosition * -1 / listPercent;
    currentPercent = Math.floor(currentPercent);

    if (currentPercent > 100) currentPercent = 100;
    if (currentPercent < 0) currentPercent = 0;

    let coefficient = 3.5;

    for(let breakpoint in collectionSlider.options.breakpoints) {
      const intBreakpoint = parseInt(breakpoint);

      if (window.innerWidth <= intBreakpoint) {
        switch(intBreakpoint) {
          case 1360:
            coefficient = 4;
            break;
        }
      }
    }

    const parallaxPercent = (currentPercent - 50) / coefficient;

    return isParallax ? parallaxPercent : currentPercent;
  };

  const getSlideWidth = () => {
    const slider = $(collectionSlider.root);
    const items = slider.find('.splide__slide');

    return items.width() + collectionSlider.options.gap;
  };

  const getOneSlidePercent = () => {
    const length = getListLength();
    const widthSlider = getSlideWidth();
    return widthSlider / (length / 100)
  };

  const setArrows = () => {
    const self = $(collectionSlider.root);

    const arrow_prev = self.find('.collection-slider__arrow--prev');
    const arrow_next = self.find('.collection-slider__arrow--next');
    const arrows = self.find('.collection-slider__arrow');

    arrows.removeClass('collection-slider__arrow--disabled');

    if (getCurrentPrecent() === 0) {
      arrow_prev.addClass('collection-slider__arrow--disabled');
    }
    
    if (getCurrentPrecent() === 100) {
      arrow_next.addClass('collection-slider__arrow--disabled');
    }
  };

  const setParallax = (newIndex) => {
    setArrows();

    const self = collectionSlider.root;
    const images = $(self).find('.collection-card__img');

    if (newIndex) {
      images.css({
        'transform': `translateX(${getCurrentPrecent(true)}%)`,
        'transition': `transform ${collectionSlider.options.speed}ms ease-in-out ${collectionSlider.options.speed / 6}ms`
      });
      setTimeout(() => {
        images.removeAttr('style');
        images.css('transform', `translateX(${getCurrentPrecent(true)}%)`);
      }, collectionSlider.options.speed / 1.5 + collectionSlider.options.speed / 6);
      return true;
    }
    images.css('transform', `translateX(${getCurrentPrecent(true)}%)`);
  };
  
  const setPagination = (newIndex) => {
    setArrows();

    let percent = getCurrentPrecent();
    if ( percent < 5 )
      percent = 5;
      
    const self = collectionSlider.root;
    const roundPercent = Math.round(percent / 10) * 10;
    const progressbar = self.querySelector('.collection-slider__progressbar-item');
    const contentSlide = self.querySelector('.collection-slider__item--first')
    
    if ( percent >= getOneSlidePercent() ) {
      contentSlide.classList.add('collection-slider__item--disappeared')
    }
    else {
      contentSlide.classList.remove('collection-slider__item--disappeared')
    }   

    progressbar.style.width = newIndex ? roundPercent : percent + '%'; 
  };

  const combine = (newIndex = null) => {
    setPagination(newIndex);
    setParallax(newIndex);
  };

  const onClickArrows = (e) => {
    const self = $(e.currentTarget);
    const slider = $(collectionSlider.root);
    const list = slider.find('.splide__list');

    let width = getSlideWidth() + 11; 
    if (self.hasClass('collection-slider__arrow--prev')) width = width * (-1);

    let result = width + getCurrentPosition() * -1;
    if ( result > getListLength() ) result = getListLength();
    if ( result < 0 ) result = 0;

    result = Math.floor(result);

    list.css({
      transform: `translateX(${result * (-1)}px)`,
      transition: `transform ${collectionSlider.options.speed}ms ease-in-out .1s`
    });
    combine(1);
    setTimeout(() => {
      combine();
    }, collectionSlider.options.speed);
  };

  collectionSlider.mount();

  collectionSlider.on('move', setParallax);
  collectionSlider.on('moved', setPagination);
  collectionSlider.on('dragging', combine);
  collectionSlider.on('dragged', combine);

  $('body').on('click', '.collection-slider__arrow', onClickArrows);
  
  combine();
}; 

export default collection;