import $ from 'jquery';

const shiftCircles = () => {
  let defOne = 0;
  let defTwo = 0;
  let cirOne = 0;
  let cirTwo = 360;

  const start = () => {
    const rotSpeed = 0.0025;
    const desCirSpeed = 1;

    const canvas = document.querySelector('#shift-circles'); 
  
    if (!canvas || !canvas.getContext)
      return false;

    const ctx = canvas.getContext('2d');

    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    ctx.clearRect(0,0,width,height);
    
    const centerX = width / 2;
    const centerY = height / 2;

    const strokeWidth = 0.8;
    const strokeColor = '#fff';

    const windowTop = window.scrollY;
    const canvasY = $(canvas).offset().top + canvas.offsetHeight /2;
    const offsetOne = 400;
    const offsetTwo = 300;
    const percentOne = offsetOne / 50;
    const percentTwo = offsetTwo / 50;
    const bottomBorder = canvasY - 100;
    
    const offsetForOp = 100;
    const percentOp = offsetForOp / 50;
    const bottomBorderOp = canvasY - 150;
    
    const opCurrentPercent = (bottomBorderOp - windowTop) / percentOp;

    let currentPercentOne = ((bottomBorder - windowTop) / percentOne - 100) * (-1);
    let currentPercentTwo = ((bottomBorder - windowTop) / percentTwo - 100) * (-1);

    if (currentPercentOne > 100) currentPercentOne = 100;
    if (currentPercentOne < 0) currentPercentOne = -1;
    
    if (currentPercentTwo > 100) currentPercentTwo = 100;
    if (currentPercentTwo < 0) currentPercentTwo = -1;

    $('.about-logo').css('opacity', opCurrentPercent / 100);
    $('.about-box').css('transform', `translateY(${Math.floor( currentPercentTwo / 2 )}px)`);

    // disapired Animation
    if (defOne < currentPercentOne) {
      defOne = defOne+desCirSpeed;
      if (defOne > currentPercentOne) defOne = currentPercentOne;
    }
    if (defOne > currentPercentOne) {
      defOne = defOne-desCirSpeed;
      if (defOne < currentPercentOne) defOne = currentPercentOne;
    }

    if (defTwo < currentPercentTwo) {
      defTwo = defTwo+desCirSpeed;
      if (defTwo > currentPercentTwo) defTwo = currentPercentTwo;
    }
    if (defTwo > currentPercentTwo) {
      defTwo = defTwo-desCirSpeed;
      if (defTwo < currentPercentTwo) defTwo = currentPercentTwo;
    }

    // rotate Animation
    cirOne = cirOne + rotSpeed;
    if( cirOne === 360)
      cirOne = 0;

    cirTwo = cirTwo - rotSpeed;
    if(cirTwo === 0)
      cirTwo = 360;

    const drawCircle = (width, part, missing = -1, cof = 2, cir) => {

      const radius = width / 2;
      const angle = (Math.PI * radius) / (part * 180);

      for (let i=0; i<part*cof+10; i++) {
        if (i%2===0 && i > missing) {
          const start = cir + i * angle;
          const end = start + angle;

          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, start, end);
          ctx.lineWidth = strokeWidth;
          ctx.strokeStyle = strokeColor;
          ctx.stroke();
        }
      }
    };

    drawCircle(width - 40, 150, defOne*3.5, 2.2, cirOne);
    drawCircle((width - 40) / 2, 45, defTwo*3, 4.2, cirTwo);
    ctx.restore();
    ctx.save();

    window.requestAnimationFrame(start);
  }

  start();
};

export default shiftCircles;