import $ from 'jquery';
import Splide from '@splidejs/splide';

const personalizeSlider = () => {
  if ($('.personalize-slider').length === 0)
    return false;

  $('.personalize-slider').map((index, item) => {
    const slider = $(item);
    const slides = slider.find('.splide__list li');
    const pagWrap = slider.find('.personalize-slider__pagination');
    
    slides.map((index, item) => {
      const slide = $(item);

      const icon = slide.find('svg');
      const iconInner = icon.html();
      const iconView = icon.attr('viewBox') ? icon.attr('viewBox') : '';
      const iconXmlns = icon.attr('xmlns') ? icon.attr('xmlns') : '';
      const text = slide.find('.personalize-card__title').text();
      let linkClasses = 'personalize-slider__pagination-link';
      if (index === 0)
        linkClasses = linkClasses+' personalize-slider__pagination-link--active';

      const innerHTHML = `
        <li class="personalize-slider__pagination-item">
          <a class="${linkClasses}" href="javascript:;">
            <svg width="30" height="30" class="personalize-slider__pagination-icon" viewBox="${iconView}" xmlns="${iconXmlns}">
              ${iconInner}
            </svg>
            ${text}
          </a>
        </li>
      `;

      pagWrap.html(pagWrap.html()+innerHTHML);
      
      const counters = slide.find('.personalize-card__slide');

      counters.map((value, item) => {
        const counter = $(item);

        counter.html(
          counter.html().replace( '[current_slide]', index+1 )
        );
        counter.html(
          counter.html().replace( '[all_slides]', slides.length )
        );
      });
    });
  });

  const listLink = 'personalize-slider__pagination-link';
  const linkActiveLink = listLink+'--active';

  const persSlider = new Splide(".personalize-slider", {
    perPage: 1,
    perMove: 1,
    speed: 2000,
    gap: 'var(--column-gap)',
    pagination: true,
    arrows: false, 
    lazyLoad: 'sequential'
  });

  const chagedSlide = () => {
    const self = $(persSlider.root);
    const currentSlide = persSlider.index;
    const pagList = self.find('.'+listLink);
    pagList.removeClass(linkActiveLink);
    $(pagList[currentSlide]).addClass(linkActiveLink);
  }

  persSlider.mount();
  persSlider.on('move drag', chagedSlide);

  $(persSlider.root).find('.'+listLink).map((index, item) => {
    $(item).on('click', (e) => {
      e.preventDefault();
      persSlider.go(index);
    })
  });
};

export default personalizeSlider;