import $ from 'jquery';
import favicon from 'favicon.js';

const faviconJS = async () => {
  try {
    const res = await fetch($('body').data('favicon'));
    const images = await res.json();
    favicon.animate(images.base64, 100);
  } catch(err) {
    console.warn(err);
  }
};

export default faviconJS;  