import $ from 'jquery';

const mobile = () => {
  const hamID = '#ham';

  const ham = $(hamID);
  const body = $('body')

  const activeClass = 'menu-active';

  body.on('click', hamID, (e) => {
    e.preventDefault();
    body.toggleClass(activeClass);
  });

  body.on('click', '.mobile-menu .menu-item-has-children > a', (e) => {
    e.stopPropagation();
    e.preventDefault();

    const self = $(e.target);
    const parent = self.parent('.menu-item-has-children');
    
    parent.toggleClass('menu-item-active')
    parent.find('.sub-menu').slideToggle();
  });
};

export default mobile;