import $ from 'jquery';

const searchPopup = () => {
  const acitveClass = 'popup-search-active';

  $('body').on('click', (e) => {
    const target = $(e.target);

    if ($('body').hasClass(acitveClass)) {
      if (
        target.hasClass('popup-search')
        ||
        target.parents('.popup-search').length === 0
      ) {
        $('body').removeClass(acitveClass);
        $('.active-search-button').removeClass('active-search-button');
      }
    }
  });

  $('body').on('click', '.btn-search', (e) => {
    e.preventDefault();
    const self = $(e.currentTarget);

    setTimeout(() => {
      $('body').addClass(acitveClass);
      self.addClass('active-search-button');
    }, 0);
  });

  $('body').on('click', '.popup-search__close', (e) => {
    e.preventDefault();
    $('body').removeClass(acitveClass);
    $('.active-search-button').removeClass('active-search-button');
  });
};

export default searchPopup;