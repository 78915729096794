import './import-jquery';
import '@fancyapps/fancybox';

export default (function ($, window) {
  $(function () {
    const openClass = 'animate__fadeInDown';
    const closeClass = 'animate__fadeOutUp';

    const closePopup = () => {
      const el = $('.fancybox-slide--current .popup');

      el.removeClass(openClass);
      el.addClass(closeClass);

      el.on('animationend', (e) => {
        el.off();
        el.removeClass(closeClass);
        $.fancybox.close();
      });
    }

    window.closePopup = closePopup;

    $('body').on('click', '[data-fancybox]', (e) => {
      e.preventDefault();
      $.fancybox.open({
        src: '#'+$(e.currentTarget).attr('href'),
        modal: true,
        touch: false,
        afterShow: function() {
          $('.fancybox-slide--current .popup').addClass(openClass);
        }
      });
    });

    $('body').on('click', '[href="contact-popup"]', (e) => {
      e.preventDefault();
      $.fancybox.close();
      $.fancybox.open({
        src: '#contact-popup',
        modal: true,
        touch: false,
        afterShow: function() {
          $('.fancybox-slide--current .popup').addClass(openClass);
        }
      });
    });

    $('body').on('click', '.popup-close', (e) => {
      e.preventDefault();
      closePopup();
    });
  });
})(jQuery, window);