import $ from 'jquery';
import Blazy from 'blazy';
import {WOW} from 'wowjs';

import './componetns/fancybox';

import svgLoader from './componetns/svgLoader';
import favicon from './componetns/favicon';
import searchPopup from './componetns/popups/search';
import slidersInit from './componetns/sliders/sliders-init';
import windowResizeInit from './componetns/window-resize';
import shiftCircles from './componetns/shift-circles';
import mobile from './componetns/mobile';
import sendEmail from './componetns/send-email';


$(async function () {
  const blazy = new Blazy();
  const wow = new WOW({
    animateClass: 'animate__animated'
  });
  
  mobile();
  
  await svgLoader();
  
  searchPopup();
  shiftCircles();
  
  slidersInit();
  windowResizeInit();

  sendEmail();
  
  $('body').removeClass('loading');
  
  wow.init();
  favicon();
});